import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
//import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk'

import DataReducer from './reducer'

/*
const savioMiddleware = (store)=>(next)=>(action)=>
{
  console.log("savioMiddleware:", action);
  next(action);
}
const savioReduxLogger = createLogger({

});
*/
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let DataStore = createStore(combineReducers({
    DataReducer,
    
  }),
  {},
  composeEnhancers(applyMiddleware(thunk))); 
  
  export default DataStore;
