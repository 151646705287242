import React, { Component } from 'react';
import Switch from 'react-js-switch';
import '../style/style.css'

class  OutputCmd extends Component {
    render () {
        return (
        
        <div class="container ">
            <div class="row  ">
                <div class="col ">
                {this.props.Name}
                </div>
                <div class="col ">
                {<div className={this.props.Value?"outputstyle_Led outputstyle_Name outputstyle_Led_ON":"outputstyle_Led outputstyle_Name outputstyle_Led_OFF"}></div>}   
                <Switch 
                 backgroundColor={ {on :'#09ca60', off: '#ee6969' }}
                 size = {50}
                 borderColor ={ {on :'#32b36c;', off: '#32b36c;' }} 
                 color={'#b3c0b9' } 
                 ease={'easeOutElastic' }
                 duration={3000} 
                 value={this.props.Value}
                 onChange={() => this.props.OutCmd(this.props.Id)}/>
                
                </div>
            </div>
        </div>
      
            
        );
    }
}

export default OutputCmd;