import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePage } from '../redux/action'
import Tab from './Tab'
import {PAGE_ID} from '../redux/Dataconst'
//import PropTypes from 'prop-types';

class  Head extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(id) 
    {

        this.props.onChangePage(id);
        
    }


    render () {
var dataHome={name :'Home',id:PAGE_ID.HOME,};
var dataWifi={name :'Wifi setup',id:PAGE_ID.WIFI,};
var dataView={name :'View data',id:PAGE_ID.VIEW,};
var dataTest={name :'Test',id:PAGE_ID.TEST,};
        return (
            
            <div class="btn-group align-middle" role="group" >
                <Tab isActive={this.props.data.id_page===dataHome.id?true:false} changeTab={this.handleClick}  data={dataHome}/>
                <Tab isActive={this.props.data.id_page===dataWifi.id?true:false}changeTab={this.handleClick}  data={dataWifi}/>
                <Tab isActive={this.props.data.id_page===dataView.id?true:false}changeTab={this.handleClick}  data={dataView}/>
                <Tab isActive={this.props.data.id_page===dataTest.id?true:false}changeTab={this.handleClick}  data={dataTest}/>      
            </div>
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        onChangePage: (n) => {
            dispatch(changePage(n))
          },      
    }
}
export default connect(mapStateToProp, mapDispatchToProp)(Head);
