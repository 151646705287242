import React, { Component } from 'react';
import Switch from 'react-js-switch';
import '../style/style.css'

class  DataViewT extends Component {
    render () {
       if(this.props.Data.active)
       {
            return (
        
                <div className='ViewDat_container' >
                    <div class="row  ">
                        <div class="col ViewDataStyle_col1 ">
                        {this.props.Data.name}
                        </div>
                        <div class="col ViewDataStyle_col2">
                        {this.props.Data.val}
                        </div>
                    </div>
                </div>
          
                
            );
        }
       
    }
}

export default DataViewT;