export function changePage(n) 
{
    return {
        type    :'SET_PAGE_Id',
        payload :  n  
    };
}

export function SetOutputSate(Id,n) 
{
    return {
        type    :'SET_OUTPUT_VALUE',
        payload :  {id: Id ,value: n} 
    };
}
export function SetPrgrmType(n) 
{
    return {
        type    :'SET_PRGRM_TYPE',
        payload : n
    };
}
export function SetSlrDataValue(Id,Val) 
{
    return {
        type    :'SET_SOLARDATA_VALUE',
        payload :  {id: Id ,value: Val} 
    };
}