import {SET_PAGE_Id,SET_OUTPUT_VALUE,SET_PRGRM_TYPE,SET_SOLARDATA_VALUE } from './actions'

const initialDataState = 
{
    id_page                 : 1,
    OutValue                :[false,false,false,false,false,false,false,false,false,false],
    prgmType                :'program 1',
    SolarData               :[
                                {
                                    name: "GridVoltage",
                                    id:  1,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "GridFrquncy",
                                    id:  2,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "OutPutVoltage",
                                    id:  3,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "OutputFrquncy",
                                    id:  4,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "AprntPower",
                                    id:  5,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "ActivePower",
                                    id:  6,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "LoadPerc",
                                    id:  7,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "DCBusVoltge",
                                    id:  8,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "BttryVoltage",
                                    id:  9,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "BttryChrgngCurrnt",
                                    id:  10,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "BttryCapctyPerc",
                                    id:  11,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "InvrtHeatSink",
                                    id:  12,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "PV1InputCurrnt",
                                    id:  13,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "PV1InputVltage",
                                    id:  14,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "SCCBttryVltge",
                                    id:  15,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "BttryDischCurrnt",
                                    id:  16,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "DeviceStatus",
                                    id:  17,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "PV1PowrCharging",
                                    id:  18,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "DevcFault",
                                    id:  19,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "PrgrmTypeState",
                                    id:  20,
                                    val: 0,
                                    active:false,
                                },
                                {
                                    name: "OutPutState",
                                    id:  21,
                                    val: 0,
                                    active:true,
                                },
                                {
                                    name: "PumpCurrnt",
                                    id:  22,
                                    val: 0,
                                    active:false,
                                },
                                {
                                    name: "DeviceStates",
                                    id:  23,
                                    val: 0,
                                    active:false,
                                },
                                {
                                    name: "Spare3",
                                    id:  24,
                                    val: 0,
                                    active:false,
                                },
                                {
                                    name: "Spare4",
                                    id:  25,
                                    val: 0,
                                    active:false,
                                },
                            ],


 

}
const DataReducer = (state=initialDataState, action)=>
{
    switch(action.type)
    {
//-------------------------------------------
      case SET_PAGE_Id:
      state = {
        ...state,
        id_page   : action.payload,
      };
      break;
     case SET_OUTPUT_VALUE:
         let outputstate=[];
         outputstate=state.OutValue;
         outputstate[action.payload.id]=action.payload.value;
        state = {
            ...state,
            OutValue   : outputstate,
          };
     break;
     case SET_PRGRM_TYPE:
        state = {
            ...state,
            prgmType   : action.payload,
          };
    break;
    case SET_SOLARDATA_VALUE:
        let slrData=[];
         slrData=state.SolarData;
         state.SolarData.find(elem=>elem.id===action.payload.id).val=action.payload.value;
   state = {
       ...state,
       slrData   : slrData,
     };

    break;
//-------------------------------------------
      default:
       break;
   }
   return state;
};

export default DataReducer;