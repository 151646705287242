import './style/style.css'
import Body from './script/body'
import Head from './script/head'
import Footer from './script/footer'
import React from 'react';


function EspApp() {
  return (
<div className="  text-white bg-dark  " >
  <div className=" Headpage">
        <Head/>
  </div>
  <div className=" bodypage" >
        <Body/>
  </div>
  <div className=" footerStyle" >
        <Footer/>
  </div>
</div>
  );
}

export default EspApp;
