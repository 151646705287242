import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePage } from '../redux/action'
import WifiNameList from './WifiNameList'
import{SendDataRqst} from './ServerRqst'
import '../style/style.css'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
class  WifiSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            WifiList      : [],
            ShowModal     :false,
            Passwordname  :"password", 
            inputpassword :"",
        };
     this.setupWifi=this.setupWifi.bind(this);
     this.RefreshWifi=this.RefreshWifi.bind(this);
     this.CallbackFunction=this.CallbackFunction.bind(this);
     this.showmodalToggleOn=this.showmodalToggleOn.bind(this);
     this.showmodalToggleOFF=this.showmodalToggleOFF.bind(this);
     this.handleChange=this.handleChange.bind(this);
     this.SwitchToTelegram  =this.SwitchToTelegram.bind(this);
    }
    componentDidMount() {
        this.RefreshWifi();
      }
      handleChange(event) {
       var a= event.target.value;
       this.setState({inputpassword:a});
      }
    setupWifi()
    {
      if(this.state.inputpassword==="")
      {
        alert("password is empty");
      }
      else
      {
        this.setState({ShowModal:false});
        SendDataRqst('/Wifisetup/'+this.state.Passwordname+'/'+this.state.inputpassword,this.CallbackFunction);
      }
      
        
    }
    RefreshWifi()
    {
        SendDataRqst('/WifiList',this.CallbackFunction);
    }
    SwitchToTelegram()
    {
        SendDataRqst('/SWitchToTelgrm',this.CallbackFunction);
    }
    showmodalToggleOn(name)
    {
      this.setState({ShowModal:true});
      this.setState({Passwordname:name});
      this.setState({inputpassword:""});
    }
    showmodalToggleOFF()
    {
      this.setState({ShowModal:false});
    }
    CallbackFunction(dat)
    {
       
        let array =dat.split(';');
        let item;
        var finalObj=[];
        for(let ind =0 ;ind< array.length;ind++)
        {
            item=array[ind];
            finalObj.push(item);
        }

        this.setState({WifiList:finalObj});
    }
    render () {
      

        //let WifiList=['Wifi_list_1','Wifi_list_2','Wifi_list_3','Wifi_list_4','Wifi_list_5','Wifi_list_6']
        let counter =0;
        let m=this.state.WifiList.map(elm=><WifiNameList Name ={elm} WifiSetup={this.showmodalToggleOn} id={counter++}/>)
        return (
            <div class="smartphone">
                <button  onClick={this.RefreshWifi}  type="button" class="btn btn-success WifiRefreshButton">Refresh</button>
              
                <div>
                   {m}
                </div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.showmodalToggleOFF}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                    <Modal.Title  >wifi setup</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    Wifi Id: {this.state.Passwordname}
                    <InputGroup className="mb-3">
                    <Form.Label column sm="2">
                      Password
                   </Form.Label>
                      <Col sm="10">
                      <Form.Control type="password" placeholder="Password" onChange={this.handleChange} />
                     </Col>
                       </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.showmodalToggleOFF}>
                    Close
                    </Button>
                    <Button variant="primary" onClick={this.setupWifi} >Save wifi</Button>
                  </Modal.Footer>
                </Modal>
            </div>
            
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        onChangePage: (n) => {
            dispatch(changePage(n))
          },      

    }
}
export default connect(mapStateToProp, mapDispatchToProp)(WifiSetup);