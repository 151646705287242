import React, { Component } from 'react';
import Switch from 'react-js-switch';
import '../style/style.css'


class  WifiNameList extends Component {
    render () {
        return (
        
            
        <div>
            <button  onClick={() =>this.props.WifiSetup(this.props.Name)} type="button" class="btn btn-link">{this.props.Name}</button>
        
        </div>
      
            
        );
    }
}

export default WifiNameList;