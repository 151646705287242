import React, { Component } from 'react';


class  Tab extends Component {
    render () {
        return (
            
                <button onClick={() => this.props.changeTab(this.props.data.id)} type="button" className={this.props.isActive ?"btn btn-success":"btn  btn btn-secondary" }>
                  {this.props.data.name}
                </button>
        
            
        );
    }
}

export default Tab;