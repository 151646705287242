import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePage } from '../redux/action'

class  Home extends Component {

    constructor(props) {
        super(props);

     
    }


    render () {
   
        return (
            <div class="card border-light mb-3">
                <div class="card-header bg-transparent border-success text-danger">
                    <h5 class="font-weight-bold"> Signin</h5>
                </div>
                <div class="card-body text-success">
                    <div >
                        <form class="px-4 py-3">
                            <div class="form-group">
                                <label>Username</label>        
                                <input type="username" class="form-control"  placeholder="Username"/>
                            </div>
                            <div class="form-group">
                                <label >Password</label>
                                <input type="password" class="form-control" placeholder="Password"/>
                            </div>
                            <button  class="btn btn-success">Sign in</button>
                        </form>
                        <div class="dropdown-divider"></div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        onChangePage: (n) => {
            dispatch(changePage(n))
          },      

    }
}
export default connect(mapStateToProp, mapDispatchToProp)(Home);