/*$servername = "localhost";
// Your Database name
$dbname = "s67rys4w_KA_SOLAR_DATABASE";
// Your Database user
$username = "s67rys4w_KA_SOLAR";
// Your Database user password
$password ="Kassem129?";*/
export const Board_Id               =1;
function appGetServer() {
    return document.location.hostname;
    //return "192.168.43.21";
   // return "solar.websitelb.com/";
}

export function SendDataRqst(rqst,cllbckfunction) {

    var xhttp = new XMLHttpRequest();
    xhttp.onload  = function() {
    if (this.readyState === 4 && this.status === 200) {
        cllbckfunction(this.responseText);
    }
  };
     xhttp.open("GET", 'https://'+ appGetServer()+rqst,true);

  xhttp.send();
}