import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePage,SetSlrDataValue } from '../redux/action'
import DataViewT from './DataViewT'
import '../style/style.css'
import{SendDataRqst,Board_Id} from './ServerRqst'



class  View extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Data      : [],
        };
     this.GetData=this.GetData.bind(this);
     this.datacallback=this.datacallback.bind(this);
    }
    GetData()
    {
        SendDataRqst("/php_server/SolarKA_Data_action.php?action=GetData&board=" + Board_Id,this.datacallback);
        
    }
    datacallback(dat)
    {
        let array= JSON.parse(dat);
        for(let ind =0 ;ind< array.length;ind++)
        {
            this.props.onSetSlrDataValue(parseFloat(array[ind].id),parseFloat(array[ind].val));
        }

      /*  let array =dat.split(';');
        let item;
        var Objcont;
        var finalObj=[];
        for(let ind =0 ;ind< array.length;ind++)
        {
            item=array[ind].split(',');
            Objcont={Name:item[0],Value :item[1] };
            finalObj.push(Objcont);
        }

        this.setState({Data:finalObj});*/
    }
    componentDidMount() {
        this.GetData();
        this.interval = setInterval(() => {
        this.GetData();
        }, 5000);
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }
    
    render () {
       
let counter =0;
let m=this.props.data.SolarData.map(item =><DataViewT Data={item} id ={counter++} />);
        return (
        <div class="container ">
            <div class="row">
            {m}
          </div>
        </div>
        
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        onChangePage: (n) => {
            dispatch(changePage(n))
          },      
        onSetSlrDataValue: (id,val) => {
            dispatch(SetSlrDataValue(id,val))
          },
    }
}
export default connect(mapStateToProp, mapDispatchToProp)(View);