import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
class  PrgrmSelect extends Component {
    render () {
        let prgrm=['program 1','program 2','program 3','program 4','program 5'];
        let counter = 0;
        let list = prgrm.map(item => <Dropdown.Item key={`elem_${counter++}`} onClick={ () => this.props.ChangePrgrm(item)} name={item} className="dropdown-item">{item}</Dropdown.Item>);
        return (


          <Dropdown >
          <Dropdown.Toggle variant="success" id="dropdown-autoclose-true">
          {this.props.PrgrmInd<prgrm.length?prgrm[this.props.PrgrmInd]:prgrm[0]}
          </Dropdown.Toggle>
    
          <Dropdown.Menu >
          {list}
          </Dropdown.Menu>
        </Dropdown>

        );
      }
    };

        


export default PrgrmSelect;