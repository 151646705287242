import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changePage } from '../redux/action'
import {PAGE_ID} from '../redux/Dataconst'
import Home from './Home'
import Test from './Test'
import View from './View'
import WifiSetup from './Wifisetup'
class  Body extends Component {

    constructor(props) {
        super(props);

        this.page = this.page.bind(this);
    }

page()
{
    let l;
    switch(this.props.data.id_page)
    {
        default:
            l='';
        case PAGE_ID.HOME:
            l=<Home/>;
        break;
        case PAGE_ID.WIFI:
            l=<WifiSetup/>;
        break;
        case PAGE_ID.VIEW:
            l=<View/>;
        break;
        case PAGE_ID.TEST:
            l=<Test/>;
        break;
    }
   return l;
}
  render () {
    
        return (
            <div  style={{
                height: '100%'
              }}>
                <div>
                   {this.page()}
                </div>
            </div>
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        onChangePage: (n) => {
            dispatch(changePage(n))
          },      

    }
}
export default connect(mapStateToProp, mapDispatchToProp)(Body);
