import React, { Component } from 'react';
import { connect } from 'react-redux'
import { SetOutputSate,SetPrgrmType,SetSlrDataValue} from '../redux/action'
import OutputCmd from './OutputCmd'
import{SendDataRqst,Board_Id} from './ServerRqst'
import PrgrmSelect  from './PrgrmSelect'


class  Test extends Component {

    constructor(props) {
        super(props);

     this.OutCmdCtrl    =this.OutCmdCtrl.bind(this);
     this.changePgrm    =this.changePgrm.bind(this);
     this.OutData_call  =this.OutData_call.bind(this);
     this.CallBackFunction2    =this.CallBackFunction2.bind(this);
     this.CallBackFunction    =this.CallBackFunction.bind(this);
    }
    CallBackFunction(dat)
    {
       
        
    }
    CallBackFunction2(dat)
    {
        let array= JSON.parse(dat);
        for(let ind =0 ;ind< array.length;ind++)
        {
            this.props.onSetSlrDataValue(parseFloat(array[ind].id),parseFloat(array[ind].val));
        }

    }
    OutData_call()
    {

        SendDataRqst("/php_server/SolarKA_Data_action.php?action=GetData&board=" + Board_Id,this.CallBackFunction2);
    }
    changePgrm(PrgmInd)
    {   let prgrm_indx=0;
        switch(PrgmInd)
        {
            case 'program 1':
                prgrm_indx=0;
                break;
            case 'program 2':
                prgrm_indx=1;
                break;
            case 'program 3':
                prgrm_indx=2;
                break; 
            case 'program 4':
                prgrm_indx=3;
                break;
            case 'program 5':
                prgrm_indx=4;
            break;
        }
        this.props.onSetSlrDataValue(20,prgrm_indx);
        let out_HostLink="";
        out_HostLink="/php_server/SolarKA_Data_action.php?action=SetProgrmType&board="+ Board_Id +"&val=" + prgrm_indx;
        SendDataRqst(out_HostLink,this.CallBackFunction);
    }
    OutCmdCtrl(id)
    {
       
        let out_val=this.props.data.SolarData.find(Ele=>Ele.id===21).val;
        let out_HostLink="";
        if((out_val)&(1<<id))
        {
            out_val&=~(1<<id);
            
        }
        else
        {
            out_val|=(1<<id);
        }
        this.props.onSetSlrDataValue(21,out_val);
        out_HostLink="/php_server/SolarKA_Data_action.php?action=SetOutval&board="+ Board_Id +"&val=" + out_val;
        SendDataRqst(out_HostLink,this.CallBackFunction);
    }
    componentDidMount() {
        this.OutData_call();
        this.interval = setInterval(() => {
        this.OutData_call()
        }, 5000);
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }
    render () {   
let out=['out 1','out 2','out 3','out 4','out 5','out 6'];
let counter =0;
let out_val=this.props.data.SolarData.find(Ele=>Ele.id===21).val;
let m=out.map(elem=> 
        <div>
        <OutputCmd Value ={((out_val)&(1<<counter))?true:false} OutCmd={this.OutCmdCtrl} Name={elem} Id={counter++}/>
    </div>);
     

        return (
            <div class="smartphone">
                <div>
                    <PrgrmSelect ChangePrgrm={this.changePgrm }PrgrmInd ={this.props.data.SolarData.find(Ele=>Ele.id===20).val}/>
                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <button   className="dropdown-item"></button>
                    </div>
                </div>
                {m}
            </div>
        );
    }
}
const mapStateToProp = (state) => {
    return {
        data: state.DataReducer,
    }
}
const mapDispatchToProp = (dispatch) => {
    return {
        SetOutputSate: (id,n) => {
            dispatch(SetOutputSate(id,n))
          },
          SetPrgrmType: (n) => {
            dispatch(SetPrgrmType(n))   

         },  
         onSetSlrDataValue: (id,val) => {
            dispatch(SetSlrDataValue(id,val))
          },    

    }
}
export default connect(mapStateToProp, mapDispatchToProp)(Test);